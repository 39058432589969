import React from "react";
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import "./src/scss/style.scss";

import { GlobalProvider } from "./src/context/GlobalContext";
import Layout from "./src/components/Layout";

export const onClientEntry = () => {
  console.log('onClientEntry: Checking for Cookiebot');
  const checkCookiebot = setInterval(() => {
    if (window.Cookiebot) {
      clearInterval(checkCookiebot);
      console.log('Cookiebot is available');
      
      // Define gtag function if not already defined
      window.dataLayer = window.dataLayer || [];
      function gtag() { window.dataLayer.push(arguments); }
      window.gtag = window.gtag || gtag;

      window.addEventListener("CookieConsentDeclaration", function (e) {
        const consent = window.Cookiebot.consents;
        const grantedConsents = {
          'ad_storage': consent.marketing ? 'granted' : 'denied',
          'analytics_storage': consent.statistics ? 'granted' : 'denied',
          'ad_user_data': consent.marketing ? 'granted' : 'denied',
          'ad_personalization': consent.marketing ? 'granted' : 'denied',
          'functionality_storage': consent.preferences ? 'granted' : 'denied',
          'personalization_storage': consent.preferences ? 'granted' : 'denied',
          'security_storage': 'granted'  // Typically, security storage might always be granted
        };

        gtag('consent', 'update', grantedConsents);

        if (consent.marketing) {
          console.log('Marketing consent given, loading GTM script');
          const script = document.createElement("script");
          script.src = `https://www.googletagmanager.com/gtm.js?id=${process.env.GTM_TRACKING}`;
          script.type = "text/javascript";
          script.async = true;
          document.head.appendChild(script);

          // Add noscript part for GTM
          const noscript = document.createElement("noscript");
          noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=${process.env.GTM_TRACKING}" height="0" width="0" style="display:none;visibility:hidden"></iframe>`;
          document.body.appendChild(noscript);

          script.onload = () => {
            console.log('GTM script loaded');
          };
        } else {
          console.log('Marketing consent not given');
        }
      });
    }
  }, 100);
};

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const wrapRootElement = ({ element }) => (
  <GlobalProvider>{element}</GlobalProvider>
);
